import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

import moment from 'moment'

const config = {
    apiKey: "AIzaSyBqG3cx5d_k0AAZSntGQejg28J3t2kdce0",
    authDomain: "sistema-lohr.firebaseapp.com",
    databaseURL: "https://sistema-lohr.firebaseio.com",
    projectId: "sistema-lohr",
    storageBucket: "sistema-lohr.appspot.com",
    messagingSenderId: "553229372576",
    appId: "1:553229372576:web:0d14d53ab39908c2"
}

class Firebase {
	constructor() {
		firebase.initializeApp(config)
		this.auth = firebase.auth()
        this.firestore = firebase.firestore()
        this.storage = firebase.storage()
	}

	login(email, password) {
		return this.auth.signInWithEmailAndPassword(email, password)
	}

	logout() {
		return this.auth.signOut()
	}

	async register(name, email, password) {
		await this.auth.createUserWithEmailAndPassword(email, password)
		return this.auth.currentUser.updateProfile({
			displayName: name
		}).then(() => {
            return this.firestore.doc(`users/${this.auth.currentUser.uid}`).set({
                name,
                id: this.auth.currentUser.uid,
                rol: 'admin',
                isActive: false,
                email,
                password
            })
        })
    }

    userValidator() {
        return new Promise(resolve => {
            this.firestore.doc(`users/${this.auth.currentUser.uid}`).get().then(snap => {
                const user = snap.data();
                if (user.isActive === 'true' && user.isIndustry === 'true') {
                    resolve(true)
                }
                resolve(null)
            });
        });
    }

	isInitialized() {
		return new Promise(resolve => {
			this.auth.onAuthStateChanged(resolve)
		})
	}

	getCurrentUsername() {
		return this.auth.currentUser && this.auth.currentUser.displayName
	}
    
    getUsers() {
        const users = this.firestore.collection(`users`);
        return users
    }
    getCustomers() {
        const customers = this.firestore.collection('customers').get()
        return customers
    }
    getDirectory(customerId) {
        const directory = this.firestore.collection('directory').where('customerId','==',customerId)
        return directory
    }
    getSellers() {
        const sellers = this.firestore.collection('users').where('rol','==','seller').get()
        return sellers
    }
    getPriceLists(customerId) {
        const priceLists = this.firestore.collection('priceLists').where('customerId','==',customerId)
        return priceLists
    }
    getPresentations() {
        const presentations = this.firestore.collection('presentations').where('type','==','products').get()
        return presentations
    }
    getPrices(id) {
        const prices = this.firestore.collection('prices').where('priceListId','==',id)
        return prices
    }

    getCollectionOnce(col) {
        const data = this.firestore.collection(col).get()
        return data
    }
    getCollectionRealTime(col) {
        const data = this.firestore.collection(col)
        return data
    }
    getDocumentOnce(col,doc) {
        const data = this.firestore.collection(col).doc(doc).get()
        return data
    }
    getDocumentRealTime(col,doc) {
        const data = this.firestore.collection(col).doc(doc)
        return data
    }

    async productionMoves(type,date,item) {
        const ref = this.firestore.collection('moves').doc()
        const transRef = this.firestore.collection(type).doc(item.id)
        item.type = type
        item.date = date
        item.timestamp = moment().valueOf()
        
        await ref.set(item);
        await this.firestore.runTransaction((transaction) => {
            return transaction.get(transRef).then(doc => {
                var um = doc.data().um ? doc.data().um : 0;
                var quantity = doc.data().quantity ? doc.data().quantity : 0;

                var newUm = um + (item.umIn ? item.umIn : 0) - (item.umOut ? item.umOut : 0);

                var newQuantity = quantity + (item.quantIn ? item.quantIn : 0) - (item.quantOut ? item.quantOut : 0);

                transaction.update(transRef, { um: newUm, quantity: newQuantity });
            });
        });
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col: 'cfdis',
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Agregar',
            what: ref.id,
            userId: this.auth.currentUser.uid
        };
        return movesRef.set(move);
    }

    update(col,id,index,value) {
        return this.firestore.collection(col).doc(id).update({ [index]: value });
    }
    async simpleAdd(item,col) {
        const ref = this.firestore.collection(col).doc()
        item.id = ref.id
        item.timestamp = moment().valueOf()
        await ref.set(item);
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Agregar',
            what: ref.id,
            userId: this.auth.currentUser.uid
        };
        return movesRef.set(move);
    }
    async addCfdi(id,description) {
        const ref = this.firestore.collection('cfdis').doc(id)
        await ref.set({ id, description });
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col: 'cfdis',
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Agregar',
            what: ref.id,
            userId: this.auth.currentUser.uid
        };
        return movesRef.set(move);
    }
    async addSatPayway(id,description) {
        const ref = this.firestore.collection('satPayway').doc(id)
        await ref.set({ id, description });
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col: 'satPayway',
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Agregar',
            what: ref.id,
            userId: this.auth.currentUser.uid
        };
        return movesRef.set(move);
    }
    addBrand(logo,name,businessName) {
        const uploadFile = (file,folder) => {
            return new Promise((resolve,reject) => {
                const ext = file.name.split('.').pop()
                const fileName = `${name}.${ext}`;
                const uploadFile = this.storage.ref(folder).child(fileName).put(file);
                uploadFile.on('state_changed', snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    return progress
                }, error => {
                    reject(error)
                }, () => {
                    uploadFile.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    })
                });
            })
        }
        return uploadFile(logo,'brands').then(url => {
            const ref = this.firestore.collection('brands').doc()
            const item = {
                name,
                businessName,
                logo: url,
                id: ref.id
            }
            ref.set(item)
        })
    }

    async delete(col,doc,data) {
        const ref = this.firestore.collection(col).doc(doc)
        await ref.delete();
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Borrar',
            what: ref.id,
            userId: this.auth.currentUser.uid,
            data
        };
        return movesRef.set(move);
    }

    async quotationToPurchase(item) {
        const ref = this.firestore.collection('purchases').doc()
        item.paymentStatus = 'pending'
        item.processStatus = 'pending'
        item.id = ref.id
        item.date = moment().valueOf()
        item.timestamp = moment().valueOf()
        await ref.set(item);

        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col: 'purchases',
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Agregar',
            what: ref.id,
            userId: this.auth.currentUser.uid
        };
        await movesRef.set(move)
        return ref.id
    }
}

export default new Firebase()