import React from 'react';
import { Link, Switch, Route } from 'react-router-dom';

import Home from './home/Home';
import PromoReports from './promo-reports/PromoReports';

const App = (props) => {

    const loc = props.location.pathname.split('/')[1]

    return (
        <div className="app">
            <header>
                <div className="header__container">
                    <picture>
                        <img src="/images/ico.png" alt="Casa Lohr"/>
                    </picture>
                    <ul>
                        <Link className={loc === '' || loc === undefined ? 'active' : ''} to="/"><span>Inicio</span></Link>
                        <Link className={loc === 'nosotros' ? 'active' : ''} to="/"><span>Nosotros</span></Link>
                        <Link className={loc === 'empresas' ? 'active' : ''} to="/"><span>Empresas</span></Link>
                        <Link className={loc === 'contacto' ? 'active' : ''} to="/"><span>Contacto</span></Link>
                    </ul>
                </div>
            </header>
            <Switch>
                <Route path="/" component={Home} exact={true} />
                <Route path="/reportes-promotoria/:id" component={PromoReports} exact={true} />
            </Switch>
            <footer>
                <div className="footer__container">
                    <div className="footer__top">
                        <ul>
                            <h3>Empresas</h3>
                            <a href="https://lacholita.com.mx/">
                                La Cholita
                            </a>
                            <a href="https://elmayormx.com/">
                                El Mayor
                            </a>
                            <a href="https://coymexico.com/">
                                Coy
                            </a>
                        </ul>
                        <ul>
                            <h3>Acceso a Empleados</h3>
                            <a target="_blank" rel="noopener noreferrer" href="https://industria.casalohr.com"><span>Industria</span></a>
                            <a target="_blank" rel="noopener noreferrer" href="https://rancho.casalohr.com"><span>Rancho</span></a>
                        </ul>
                    </div>
                    <div className="footer__bottom">
                        <div className="footer__bottom-container">
                            <p>&copy; Todos los derechos reservados Grupo Lohr sa de cv.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default App;