import React, { useState,useEffect } from 'react'
import firebase from '../../firebase'
import Slider from 'react-slick'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Product = (props) => {

    const {details} = props
    const {data} = props
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <tr>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td>{data.customer}</td>
            <td>{data.store}</td>
            <td>{details.name}</td>
            <td>{numeral(details.price).format('$0,0.00')}</td>
            <td>{details.status}</td>
            <td>{details.isTimedOut}</td>
            <td>{details.fronts}</td>
            {Number(props.index) === 0 ? <td rowSpan="21">{data.comments}</td> : <td style={{ display: 'none' }}></td>}

            {Number(props.index) === 0 ? <td rowSpan="21">
                <div style={{ display: 'block', width: '30rem' }}>
                    <Slider {...settings}>
                        {data.img1 ? <img style={{ width:'30rem' }} src={`https://drive.google.com/uc?export=view&id=` + (data.img1.split('=').pop())} alt={data.store}/> : ''}
                        {data.img2 ? <img style={{ width:'30rem' }} src={`https://drive.google.com/uc?export=view&id=` + (data.img2.split('=').pop())} alt={data.store}/> : ''}
                        {data.img3 ? <img style={{ width:'30rem' }} src={`https://drive.google.com/uc?export=view&id=` + (data.img3.split('=').pop())} alt={data.store}/> : ''}
                        {data.img4 ? <img style={{ width:'30rem' }} src={`https://drive.google.com/uc?export=view&id=` + (data.img4.split('=').pop())} alt={data.store}/> : ''}
                    </Slider>
                </div>
            </td> : <td style={{ display: 'none' }}></td>}
        </tr>
    )
}

const Item = (props) => {

    const {data} = props
    const lookup = _.keyBy(props.selectedProduct, o => {
        return o.value
    })

    const products = !_.isEmpty(props.selectedProduct) ? _.filter(data.products, o => {
        return lookup[o.name] !== undefined
    }) : data.products

    return (
        <tbody>
            {
                Object.keys(products).map(key => <Product index={key} key={key} details={products[key]} data={data} />)
            }
        </tbody>
    )
}

const PromoReports = (props) => {

    const id = props.match.params.id
    const [report,setReport] = useState({})

    useEffect(() => {
        firebase.getDocumentOnce('promo-reports',id).then(snap => {
            setReport(snap.data())
        })
    },[id])

    const data = report.data ? JSON.parse(report.data) : []

    return (
        <div className="promo-reports">
            <div className="promo-reports__container">
                <table>
                    <thead>
                        <tr>
                            <th colSpan="13">{report.name}</th>
                        </tr>
                        <tr>
                            <th>Fecha</th>
                            <th>Cliente</th>
                            <th>Tienda</th>
                            <th>Producto</th>
                            <th>Precio</th>
                            <th>Estatus</th>
                            <th>¿Hay caducados?</th>
                            <th>Frentes</th>
                            <th>Comentarios</th>
                            <th>Imagenes</th>
                        </tr>
                    </thead>
                    {
                        Object.keys(data).map(key => <Item key={key} selectedProduct={report.selectedProduct} data={data[key]} />)
                    }
                </table>
            </div>
        </div>
    )
}

export default PromoReports