import React from 'react';
import { Link } from 'react-router-dom'

const Home = () => {
    return (
        <div className="home">
            <div className="cover">
                <div className="cover__container">
                    <div className="title">
                        <h1>Casa Lohr</h1>
                        <p>Empresa 100% mexicana</p>
                    </div>
                    <picture>
                        <img src="/images/1.png" alt="Casa Lohr"/>
                    </picture>
                </div>
            </div>
            <div className="home__about">
                <div className="home__about-container">
                    <h3>Acerca de <br/> nosotros</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br/><br/>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
            </div>
            <div className="home__enterprises">
                <div className="home__enterprises-container">
                    <h3>Nuestras Empresas</h3>
                    <ul>
                        <a href="https://lacholita.com.mx/">
                            <img src="/images/cholita.png" alt="Mole La Cholita"/>
                        </a>
                        <a href="https://elmayormx.com/">
                            <img src="/images/mayor.png" alt="Mole El Mayor"/>
                        </a>
                        <a href="https://coymexico.com/">
                            <img src="/images/coy.png" alt="Crema de Maní Coy"/>
                        </a>
                    </ul>
                    <Link to="/">Ver Más</Link>
                </div>
            </div>
        </div>
    )
}

export default Home;